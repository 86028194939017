var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$mq == 'mobile' || _vm.$mq == 'tablet'
      ? 'd-flex flex-column justify-center align-center'
      : ''},[_c('v-container',[_c('v-row',[(_vm.$mq === 'mobile' || _vm.$mq === 'tablet')?_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[_c('v-img',{attrs:{"src":require("@/assets/icons/1.png"),"contain":"","max-width":"200px"}})],1):_c('v-col',{class:_vm.$mq === 'mobile' || _vm.$mq === 'tablet'
            ? 'd-flex align-center justify-center'
            : 'd-flex align-center',attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-img',{attrs:{"src":require("@/assets/icons/1.png"),"contain":"","max-width":"200px"}})],1),(_vm.$mq !== 'mobile' && _vm.$mq !== 'tablet')?_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"cols":"6"}},[_c('div',[_c('v-btn',{staticClass:"darkgrey--text",attrs:{"color":"darkgrey","outlined":"","tile":"","large":""},on:{"click":function($event){return _vm.$emit('contact')}}},[_vm._v("Let's Chat")])],1)]):_vm._e()],1)],1),_c('v-container',[_c('v-row',{directives:[{name:"intersect",rawName:"v-intersect",value:({
        handler: _vm.onIntersect,
        options: _vm.options,
      }),expression:"{\n        handler: onIntersect,\n        options: options,\n      }"}],staticClass:"fill-height"},[_c('v-col',{staticClass:"fill-height",attrs:{"cols":"12"}},[_c('h2',{class:_vm.animate
              ? 'text-h3 text-md-h1 text-center prata animate__animated animate__fadeInLeft'
              : 'text-h3 text-md-h1 text-center prata ',attrs:{"id":"title"}},[_vm._v(" Social "),_c('br'),_vm._v(" Strategy "),_c('br'),_vm._v(" Growth ")]),_c('v-card-text',{staticClass:"d-flex justify-center mt-4 slide-left"},[_c('div',{class:_vm.animate
                ? 'text-center animate__animated animate__fadeInRight'
                : 'text-center',staticStyle:{"max-width":"329px"}},[_vm._v(" Your Paid Social Media Expert. ")])]),(_vm.$mq === 'mobile')?_c('v-card-text',{staticClass:"d-flex align-center justify-center"},[_c('v-btn',{staticClass:"darkgrey--text",attrs:{"color":"darkgrey","outlined":"","tile":"","large":""},on:{"click":function($event){return _vm.$emit('contact')}}},[_vm._v("Let's Chat")])],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }