var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{staticClass:"fill-height text-center justify-center"},[_c('div',{staticClass:"d-flex flex-column justify-center align-center",staticStyle:{"max-width":"700px"}},[_c('h2',{class:_vm.animate
            ? 'animate__animated animate__fadeInLeft text-h4 text-md-h2 text-center mb-8'
            : 'text-h4 text-md-h2 text-center mb-8'},[_vm._v(" Am I right for you? ")]),_c('span',{directives:[{name:"intersect",rawName:"v-intersect",value:({
          handler: _vm.onIntersect,
          options: _vm.options,
        }),expression:"{\n          handler: onIntersect,\n          options: options,\n        }"}]}),_c('div',{class:_vm.animate
            ? 'animate__animated animate__fadeInLeft text-center mb-8'
            : 'text-center mb-8'},[_vm._v(" After extensive research, the truth is that most companies don't want to hire a full time marketing professional or simply don't have the budget. I've worked to help small business owners, franchisees and newly started businesses gain confidence in their social media presence with affordable options. I work with you or your team to not only develop a solid social media advertising plan, but take you through each step in order to guarantee long term success. I understand that every business is unique, which is why I build each client custom solutions for their respective needs. ")]),_c('div',[_c('v-btn',{staticClass:"darkgrey--text text-capitalize",attrs:{"color":"darkgrey","outlined":"","tile":"","large":""},on:{"click":function($event){return _vm.$emit('contact')}}},[_vm._v("Contact Me")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }