<template>
  <div class="d-flex justify-center align-center">
    <v-container>
      <h2 class="text-h4 text-md-h2 prata mb-16">My Process</h2>
      <v-row :class="'d-flex align-center mb-16'">
        <v-col cols="2" md="1" sm="1" class="d-flex align-center">
          <div class="text-h2 text-md-h1">1</div>
        </v-col>
        <v-col cols="10" md="11" sm="11" class="d-flex flex-column justify-center">
          <h3 class="text-h4-custom">RESEARCH</h3>
          <div>
            I get to know you, your business and your customer in depth. I also analyze
            your competitors and study market trends. We'll make sure we are aligned.
          </div>
        </v-col>
      </v-row>

      <v-row :class="'d-flex align-center mb-16'">
        <v-col cols="2" md="1" sm="1" class="d-flex align-center">
          <div class="text-h2 text-md-h1">2</div>
        </v-col>
        <v-col cols="10" md="11" sm="11" class="d-flex flex-column justify-center">
          <h3 class="text-h4-custom">CAMPAIGN PLAN</h3>
          <div>
            I will develop an advertising campaign plan including budget and goals. This
            also includes designing your campaign specifically to your audience.
          </div>
        </v-col>
      </v-row>

      <v-row :class="'d-flex align-center mb-16'">
        <v-col cols="2" md="1" sm="1" class="d-flex align-center">
          <div class="text-h2 text-md-h1">3</div>
        </v-col>
        <v-col cols="10" md="11" sm="11" class="d-flex flex-column justify-center">
          <h3 class="text-h4-custom">PREPARATIONS</h3>
          <div>
            I'll prepare your campaign for launch & review before publishing. This process
            allows me to fine-tune your campaigns for better performance.
          </div>
        </v-col>
      </v-row>
      <span
        v-intersect="{
          handler: onIntersect,
          options: options,
        }"
      ></span>
      <v-row :class="'d-flex align-center mb-16'">
        <v-col cols="2" md="1" sm="1" class="d-flex align-center">
          <div class="text-h2 text-md-h1">4</div>
        </v-col>
        <v-col cols="10" md="11" sm="11" class="d-flex flex-column justify-center">
          <h3 class="text-h4-custom">MANAGEMENT & OPTIMIZATIONS</h3>
          <div>
            Where are we improving & where are we struggling? I will manage and optimize
            your campaign upon the agreed duration of the project and provide performance
            reports.
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "PageFour",
  data: () => ({
    animate: false,
    options: {
      threshold: [0, 0.5, 1.0],
    },
  }),
  methods: {
    onIntersect(entries) {
      this.animate = entries[0].isIntersecting;
    },
  },
};
</script>

<style></style>
