<template>
  <div>
    <v-container class="fill-height text-center justify-center">
      <div
        class="d-flex flex-column justify-center align-center"
        style="max-width: 700px"
      >
        <h2
          :class="
            animate
              ? 'animate__animated animate__fadeInLeft text-h4 text-md-h2 text-center mb-8'
              : 'text-h4 text-md-h2 text-center mb-8'
          "
        >
          Am I right for you?
        </h2>
        <span
          v-intersect="{
            handler: onIntersect,
            options: options,
          }"
        ></span>
        <div
          :class="
            animate
              ? 'animate__animated animate__fadeInLeft text-center mb-8'
              : 'text-center mb-8'
          "
        >
          After extensive research, the truth is that most companies don't want to hire a
          full time marketing professional or simply don't have the budget. I've worked to
          help small business owners, franchisees and newly started businesses gain
          confidence in their social media presence with affordable options. I work with
          you or your team to not only develop a solid social media advertising plan, but
          take you through each step in order to guarantee long term success. I understand
          that every business is unique, which is why I build each client custom solutions
          for their respective needs.
        </div>

        <div>
          <v-btn
            color="darkgrey"
            class="darkgrey--text text-capitalize"
            outlined
            tile
            large
            @click="$emit('contact')"
            >Contact Me</v-btn
          >
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "PageFive",
  data: () => ({
    animate: false,
    options: {
      threshold: [0, 0.5, 1.0],
    },
  }),
  methods: {
    onIntersect(entries) {
      this.animate = entries[0].isIntersecting;
    },
  },
};
</script>

<style></style>
