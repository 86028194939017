<template>
  <div class="fill-height">
    <v-container class="d-flex flex-column align-left justify-center fill-height">
      <v-row :class="$mq === 'mobile' ? '' : 'd-flex justify-center align-center'">
        <v-col cols="12" md="6" sm="6">
          <h1
            v-intersect="{
              handler: onIntersect,
              options: options,
            }"
            v-if="$mq !== 'mobile'"
            :class="
              animate
                ? 'text-md-h1 font-weight-bold text-align-left mb-6 banner-img darkgrey--text animate__animated animate__fadeInLeft'
                : 'text-md-h1 font-weight-bold text-align-left mb-6 banner-img darkgrey--text'
            "
            :style="$mq === 'mobile' ? '' : '7rem'"
          >
            By, Markelle
          </h1>

          <h1
            v-if="$mq === 'mobile'"
            class="text-h2 font-weight-bold text-align-left mb-0 darkgrey--text"
            :style="$mq === 'mobile' ? '' : '7rem'"
          >
            By, Markelle
          </h1>

          <v-card
            v-if="$mq == 'laptop' || $mq == 'desktop' || $mq == 'tablet'"
            flat
            :class="
              animate
                ? 'py-2 mb-6 banner-text animate__animated animate__fadeInRight'
                : 'py-2 mb-6 banner-text'
            "
            style="max-width: 600px"
            tile
            color="transparent"
          >
            Hey! I'm Markelle Kallibjian. Social Media Marketing Specialist from
            Cleveland, Ohio working with startups, franchises & locally-owned small
            businesses. My clients want forward thinking, innovative solutions to meet
            their social media advertising needs. I will work with you to solidify a
            strategy and take your brand to the next level. Are you ready to work with me?
          </v-card>
          <div v-if="$mq == 'laptop' || $mq == 'desktop' || $mq == 'tablet'">
            <v-btn
              color="darkgrey"
              @click="$emit('contact')"
              class="darkgrey--text"
              outlined
              tile
              large
              >I'm Ready</v-btn
            >
          </div>
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <div>
            <v-img
              :max-height="$mq === 'mobile' ? '' : '408px'"
              contain
              src="@/assets/images/markelle.png"
              alt="Markelle"
              :class="animate ? 'animate__animated animate__fadeIn' : ''"
            ></v-img>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-card
        v-if="$mq !== 'laptop' && $mq !== 'desktop' && $mq !== 'tablet'"
        flat
        :class="'my-6 banner-text'"
        tile
        color="transparent"
      >
        Hey! I'm Markelle Kallibjian. Social Media Marketing Specialist from Cleveland,
        Ohio working with startups, franchises & locally-owned small businesses. My
        clients want forward thinking, innovative solutions to meet their social media
        advertising needs. I will work with you to solidify a strategy and take your brand
        to the next level. Are you ready to work with me?
      </v-card>
      <div v-if="$mq !== 'laptop' && $mq !== 'desktop' && $mq !== 'tablet'">
        <v-btn
          color="darkgrey"
          @click="$emit('contact')"
          class="darkgrey--text"
          outlined
          tile
          large
          >I'm Ready</v-btn
        >
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "PageOne",
  data: () => ({
    animate: false,
    options: {
      threshold: [0, 0.5, 1.0],
    },
  }),
  methods: {
    onIntersect(entries) {
      this.animate = entries[0].isIntersecting;
    },
  },
};
</script>

<style></style>
