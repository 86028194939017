var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[_c('v-container',{staticClass:"fill-height flex-column justify-center"},[_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c('h2',{class:_vm.animate
            ? 'text-h4 text-md-h2 text-center mb-8 animate__animated animate__fadeInLeft'
            : 'text-h4 text-md-h2 text-center mb-8'},[_vm._v(" Get In Touch ")]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.error)?_c('v-alert',{attrs:{"dense":"","type":"error","dismissible":""}},[_vm._v(" "+_vm._s(_vm.error))]):_vm._e(),_c('span',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersect,
            options: _vm.options,
          }),expression:"{\n            handler: onIntersect,\n            options: options,\n          }"}]}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"rules":_vm.rules,"label":"Your Name"},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"rules":_vm.emailRules,"label":"Your Email"},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{attrs:{"rules":_vm.rules,"label":"Message"},model:{value:(_vm.data.message),callback:function ($$v) {_vm.$set(_vm.data, "message", $$v)},expression:"data.message"}})],1)],1),(_vm.showSuccess)?_c('v-alert',{attrs:{"dense":"","type":"success","dismissible":""}},[_vm._v(" Thank you for contacting us.")]):_vm._e()],1),_c('div',[_c('v-btn',{staticClass:"darkgrey--text text-capitalize",attrs:{"disabled":!_vm.valid,"color":"darkgrey","loading":_vm.loading,"outlined":"","tile":"","large":""},on:{"click":function($event){return _vm.send()}}},[_vm._v("Send Message")])],1)],1),_c('div',{class:_vm.$mq === 'mobile'
          ? 'footer-content ml-4 mb-4'
          : 'footer-content ml-8 mb-8'},[_c('div',{staticClass:"text-h5 text-md-h4"},[_vm._v("Kalli Kreative")]),_c('div',[_vm._v("Cleveland, Ohio")]),_c('div',[_vm._v("mkallibjian@gmail.com")]),_c('div',[_vm._v("216 905 7376")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }