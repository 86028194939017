var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill-height"},[_c('v-container',{staticClass:"d-flex flex-column align-left justify-center fill-height"},[_c('v-row',{class:_vm.$mq === 'mobile' ? '' : 'd-flex justify-center align-center'},[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[(_vm.$mq !== 'mobile')?_c('h1',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersect,
            options: _vm.options,
          }),expression:"{\n            handler: onIntersect,\n            options: options,\n          }"}],class:_vm.animate
              ? 'text-md-h1 font-weight-bold text-align-left mb-6 banner-img darkgrey--text animate__animated animate__fadeInLeft'
              : 'text-md-h1 font-weight-bold text-align-left mb-6 banner-img darkgrey--text',style:(_vm.$mq === 'mobile' ? '' : '7rem')},[_vm._v(" By, Markelle ")]):_vm._e(),(_vm.$mq === 'mobile')?_c('h1',{staticClass:"text-h2 font-weight-bold text-align-left mb-0 darkgrey--text",style:(_vm.$mq === 'mobile' ? '' : '7rem')},[_vm._v(" By, Markelle ")]):_vm._e(),(_vm.$mq == 'laptop' || _vm.$mq == 'desktop' || _vm.$mq == 'tablet')?_c('v-card',{class:_vm.animate
              ? 'py-2 mb-6 banner-text animate__animated animate__fadeInRight'
              : 'py-2 mb-6 banner-text',staticStyle:{"max-width":"600px"},attrs:{"flat":"","tile":"","color":"transparent"}},[_vm._v(" Hey! I'm Markelle Kallibjian. Social Media Marketing Specialist from Cleveland, Ohio working with startups, franchises & locally-owned small businesses. My clients want forward thinking, innovative solutions to meet their social media advertising needs. I will work with you to solidify a strategy and take your brand to the next level. Are you ready to work with me? ")]):_vm._e(),(_vm.$mq == 'laptop' || _vm.$mq == 'desktop' || _vm.$mq == 'tablet')?_c('div',[_c('v-btn',{staticClass:"darkgrey--text",attrs:{"color":"darkgrey","outlined":"","tile":"","large":""},on:{"click":function($event){return _vm.$emit('contact')}}},[_vm._v("I'm Ready")])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('div',[_c('v-img',{class:_vm.animate ? 'animate__animated animate__fadeIn' : '',attrs:{"max-height":_vm.$mq === 'mobile' ? '' : '408px',"contain":"","src":require("@/assets/images/markelle.png"),"alt":"Markelle"}})],1)])],1)],1),_c('v-container',[(_vm.$mq !== 'laptop' && _vm.$mq !== 'desktop' && _vm.$mq !== 'tablet')?_c('v-card',{class:'my-6 banner-text',attrs:{"flat":"","tile":"","color":"transparent"}},[_vm._v(" Hey! I'm Markelle Kallibjian. Social Media Marketing Specialist from Cleveland, Ohio working with startups, franchises & locally-owned small businesses. My clients want forward thinking, innovative solutions to meet their social media advertising needs. I will work with you to solidify a strategy and take your brand to the next level. Are you ready to work with me? ")]):_vm._e(),(_vm.$mq !== 'laptop' && _vm.$mq !== 'desktop' && _vm.$mq !== 'tablet')?_c('div',[_c('v-btn',{staticClass:"darkgrey--text",attrs:{"color":"darkgrey","outlined":"","tile":"","large":""},on:{"click":function($event){return _vm.$emit('contact')}}},[_vm._v("I'm Ready")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }