<template>
  <div>
    <!-- <v-app-bar app color="white" flat>
      <div class="font-weight-bold">Markelle</div>
    </v-app-bar> -->
    <!--     
    <v-navigation-drawer
      v-model="drawer"
      color="primary"
      width="250"
      :mini-variant="miniVariant"
      :expand-on-hover="expandOnHover"
      temporary
      fixed
      app
      dark
    >
      <v-list flat dense nav>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $mq == 'mobile'
                    ? 'title text-caption text-uppercase white--text font-weight-bold d-flex align-center'
                    : 'title white--text font-weight-bold text-uppercase d-flex align-center'
                "
              >
                Kalli Kreative
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list-item-group dark>
          <v-list-item to="/">
            <v-list-item-content>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group dark color="white">
            <template v-slot:activator>
              <v-list-item-title dark color="white">Services</v-list-item-title>
            </template>

            <v-list dense dark>
              <v-list-item link dark>
                <v-list-item-title>Brand</v-list-item-title>
              </v-list-item>
              <v-list-item link dark>
                <v-list-item-title>Social</v-list-item-title>
              </v-list-item>
              <v-list-item link dark>
                <v-list-item-title>Content</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-list-group>

          <v-list-item to="/about">
            <v-list-item-content>
              <v-list-item-title>About</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/about">
            <v-list-item-content>
              <v-list-item-title>Portfolio</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/about">
            <v-list-item-content>
              <v-list-item-title>Content</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer> -->
    <!-- <Navbar>
      <template v-if="$mq === 'mobile' || !drawer" v-slot:drawer>
        <v-app-bar-nav-icon
          tile
          icon
          v-if="$mq === 'mobile'"
          color="primary"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>
      </template>
    </Navbar> -->

    <v-main>
      <slot></slot>
    </v-main>
  </div>
</template>

<script>
// import Navbar from '@/components/Navbar'

export default {
  name: 'PublicLayout',
  // components: {
  //   Navbar: Navbar,
  // },
  data() {
    return {
      fixed: false,
      drawer: false,
      miniVariant: false,
      expandOnHover: false,
    }
  },
}
</script>
