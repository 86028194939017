<template>
  <div style="position: relative">
    <v-container class="fill-height flex-column justify-center">
      <div class="d-flex flex-column justify-center align-center">
        <h2
          :class="
            animate
              ? 'text-h4 text-md-h2 text-center mb-8 animate__animated animate__fadeInLeft'
              : 'text-h4 text-md-h2 text-center mb-8'
          "
        >
          Get In Touch
        </h2>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-alert dense type="error" dismissible v-if="error">
            {{ error }}</v-alert
          >
          <span
            v-intersect="{
              handler: onIntersect,
              options: options,
            }"
          ></span>

          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-model="data.name"
                :rules="rules"
                label="Your Name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-model="data.email"
                :rules="emailRules"
                label="Your Email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-textarea
                v-model="data.message"
                :rules="rules"
                label="Message"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-alert dense type="success" dismissible v-if="showSuccess">
            Thank you for contacting us.</v-alert
          >
        </v-form>

        <div>
          <v-btn
            :disabled="!valid"
            color="darkgrey"
            class="darkgrey--text text-capitalize"
            :loading="loading"
            outlined
            tile
            large
            @click="send()"
            >Send Message</v-btn
          >
        </div>
      </div>
      <div
        :class="
          $mq === 'mobile'
            ? 'footer-content ml-4 mb-4'
            : 'footer-content ml-8 mb-8'
        "
      >
        <div class="text-h5 text-md-h4">Kalli Kreative</div>
        <div>Cleveland, Ohio</div>
        <div>mkallibjian@gmail.com</div>
        <div>216 905 7376</div>
      </div>
    </v-container>
  </div>
</template>

<script>
import * as fb from '@/firebase'
export default {
  name: 'PageSeven',
  data: () => ({
    error: '',
    loading: false,
    showSuccess: false,
    valid: false,
    rules: [(v) => !!v || 'Required'],
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    data: {
      email: '',
      name: '',
      message: '',
      recipient: 'mkallibjian@gmail.com',
      templateId: 'd-48d248e0255240dd9607ee85a0e8f753',
    },
    animate: false,
    options: {
      threshold: [0, 0.5, 1.0],
    },
  }),
  methods: {
    onIntersect(entries) {
      this.animate = entries[0].isIntersecting
    },
    async send() {
      this.loading = true
      if (this.$refs.form.validate()) {
        const contactEmail = fb.functions.httpsCallable('contactEmail')
        try {
          contactEmail({
            name: this.data.name,
            email: this.data.email,
            message: this.data.message,
            recipient: this.data.recipient,
          })
            .then(() => {
              this.loading = false
              this.$refs.form.reset()
              this.showSuccess = true
            })
            .catch((error) => {
              this.error = error.message
              this.loading = false
            })
        } catch (error) {
          console.log(error)
        }
      }
    },
  },
}
</script>

<style>
.footer-content {
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
