<template>
  <div
    :class="
      $mq == 'mobile' || $mq == 'tablet'
        ? 'd-flex flex-column justify-center align-center'
        : ''
    "
  >
    <v-container>
      <v-row>
        <v-col
          v-if="$mq === 'mobile' || $mq === 'tablet'"
          cols="12"
          class="d-flex align-center justify-center"
        >
          <v-img src="@/assets/icons/1.png" contain max-width="200px"></v-img>
        </v-col>
        <v-col
          v-else
          cols="12"
          md="6"
          sm="12"
          :class="
            $mq === 'mobile' || $mq === 'tablet'
              ? 'd-flex align-center justify-center'
              : 'd-flex align-center'
          "
        >
          <v-img src="@/assets/icons/1.png" contain max-width="200px"></v-img>
        </v-col>

        <v-col
          v-if="$mq !== 'mobile' && $mq !== 'tablet'"
          cols="6"
          class="d-flex align-center justify-end"
        >
          <div>
            <v-btn
              color="darkgrey"
              @click="$emit('contact')"
              class="darkgrey--text"
              outlined
              tile
              large
              >Let's Chat</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row
        v-intersect="{
          handler: onIntersect,
          options: options,
        }"
        class="fill-height"
      >
        <v-col cols="12" class="fill-height">
          <!-- <kinesis-container event="scroll">
            <kinesis-element :originX="0" :strength="800" type="translate">
              <h2 class="text-h3 text-md-h1 text-center prata">
                Brand <br />
                Social <br />
                Content
              </h2>
            </kinesis-element>
          </kinesis-container> -->

          <h2
            id="title"
            :class="
              animate
                ? 'text-h3 text-md-h1 text-center prata animate__animated animate__fadeInLeft'
                : 'text-h3 text-md-h1 text-center prata '
            "
          >
            Social <br />
            Strategy <br />
            Growth
          </h2>

          <v-card-text class="d-flex justify-center mt-4 slide-left">
            <div
              style="max-width: 329px"
              :class="
                animate
                  ? 'text-center animate__animated animate__fadeInRight'
                  : 'text-center'
              "
            >
              Your Paid Social Media Expert.
            </div>
          </v-card-text>

          <v-card-text v-if="$mq === 'mobile'" class="d-flex align-center justify-center">
            <v-btn
              color="darkgrey"
              @click="$emit('contact')"
              class="darkgrey--text"
              outlined
              tile
              large
              >Let's Chat</v-btn
            >
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "PageTwo",
  data: () => ({
    animate: false,
    options: {
      threshold: [0, 0.5, 1.0],
    },
  }),
  methods: {
    onIntersect(entries) {
      this.animate = entries[0].isIntersecting;
    },
  },
};
</script>

<style></style>
