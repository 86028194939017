<template>
  <div style="postion: relative">
    <div :class="$mq === 'mobile' ? 'portfolio ' : 'portfolio '">
      <v-btn color="darkgrey" @click="openInsta()" class="darkgrey--text" text large block
        >View Portfolio <v-icon right>mdi-instagram</v-icon></v-btn
      >
    </div>

    <div v-if="$mq === 'tablet'">
      <PageTwo @contact="scrollToContact()" class="item" />
      <PageOne @contact="scrollToContact()" class="item" />
      <!-- <PageThree @contact="scrollToContact()" class="item" /> -->

      <PageFour @contact="scrollToContact()" class="item" />
      <PageFive @contact="scrollToContact()" class="item" />
      <PageSix @contact="scrollToContact()" class="item" />
      <PageSeven id="contact" ref="contact" class="item" />
    </div>

    <vue-scroll-snap v-else-if="$mq !== 'mobile'" :fullscreen="true">
      <PageTwo @contact="scrollToContact()" class="item" />
      <PageOne @contact="scrollToContact()" class="item" />
      <!-- <PageThree @contact="scrollToContact()" class="item" /> -->

      <PageFour @contact="scrollToContact()" class="item" />
      <PageFive @contact="scrollToContact()" class="item" />
      <PageSix @contact="scrollToContact()" class="item" />
      <PageSeven id="contact" ref="contact" class="item" />
    </vue-scroll-snap>

    <div v-else>
      <PageTwo @contact="scrollToContact()" class="item" />
      <hr />
      <PageOne
        @contact="scrollToContact()"
        class="item my-16 d-flex justify-center align-center flex-column"
      />
      <hr />
      <!-- <PageThree
        @contact="scrollToContact()"
        class="item my-16 d-flex justify-center align-center flex-column"
      /> -->
      <hr />
      <PageFour
        @contact="scrollToContact()"
        class="item my-16 d-flex justify-center align-center flex-column"
      />
      <hr />
      <PageFive
        @contact="scrollToContact()"
        class="item my-16 d-flex justify-center align-center flex-column"
      />
      <hr />
      <PageSix
        @contact="scrollToContact()"
        class="item my-16 d-flex justify-center align-center flex-column"
      />
      <hr />
      <PageSeven
        ref="contact"
        id="contact"
        class="item my-16 d-flex justify-center align-center flex-column"
      />
    </div>

    <!-- <v-dialog
      v-if="showPortforlio"
      v-model="showPortforlio"
      fullscreen
      persistent
    >
      <PortfolioDialog @close="showPortforlio = false" />
    </v-dialog> -->
  </div>
</template>

<script>
import PublicLayout from "@/layouts/PublicLayout";
import VueScrollSnap from "vue-scroll-snap";
import PageOne from "@/components/PageOne";
import PageTwo from "@/components/PageTwo";
// import PageThree from "@/components/PageThree";
import PageFour from "@/components/PageFour";
import PageFive from "@/components/PageFive";
import PageSix from "@/components/PageSix";
import PageSeven from "@/components/PageSeven";
// import PortfolioDialog from '@/components/PortfolioDialog'

export default {
  name: "Home",
  components: {
    VueScrollSnap,
    PageOne,
    PageTwo,
    // PageThree,
    PageFour,
    PageFive,
    PageSix,
    PageSeven,
    // PortfolioDialog,
  },
  data: () => ({
    window: 0,
    showPortforlio: false,
  }),
  created() {
    this.$emit(`update:layout`, PublicLayout);
  },
  methods: {
    scrollToContact() {
      const el = document.getElementById("contact");
      el.scrollIntoView({ behavior: "smooth" });
    },
    openInsta() {
      window.open("https://www.instagram.com/kalli_kreative/", "_blank");
    },
  },
};
</script>

<style scoped>
.item {
  min-height: 100vh;
}

.scroll-snap-container {
  height: 100vh;
  width: 100vw;
}
.portfolio {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 4;
}
</style>
