<template>
  <div class="d-flex justify-center align-center">
    <v-container>
      <h2 class="text-h4 text-md-h2 text-center prata mb-16">Packages</h2>
      <span
        v-intersect="{
          handler: onIntersect,
          options: options,
        }"
      ></span>
      <v-row class="justify-space-between">
        <v-col cols="12" md="3" sm="3" :class="'d-flex flex-column align-center'">
          <div :class="animate ? 'mb-4 animate__animated animate__flipInY' : 'mb-4'">
            <!-- <v-icon x-large>mdi-airplane</v-icon> -->
            <img src="@/assets/icons/5.png" style="width: 50px" />
          </div>
          <div class="clean-list-title">Start-Up</div>
          <ul class="clean-list">
            <li>Campaign Setup</li>
            <li>2 channels (Facebook, Instagram)</li>
            <li>1 Advertisement</li>
            <li>1 Day Management</li>
            <li>Current Campaign Audit</li>
            <li>Target Audience Research</li>
            <li>Campaign Optimization</li>
            <li>Ads Analytical Report</li>
            <li>2 Day Delivery Time</li>
          </ul>
        </v-col>
        <v-col cols="12" md="3" sm="3" :class="'d-flex flex-column align-center'">
          <div :class="animate ? 'mb-4 animate__animated animate__flipInY' : 'mb-4'">
            <!-- <v-icon x-large>mdi-briefcase</v-icon> -->
            <img src="@/assets/icons/6.png" style="width: 50px" />
          </div>
          <div class="clean-list-title">Business</div>
          <ul class="clean-list">
            <li>Campaign Setup</li>
            <li>2 channels (Facebook, Instagram)</li>
            <li>3 Advertisements</li>
            <li>15 Day Management</li>
            <li>Current Campaign Audit</li>
            <li>Target Audience Research</li>
            <li>Campaign Optimization</li>
            <li>Ads Analytical Report</li>
            <li>5 Day Delivery Time</li>
          </ul>
        </v-col>
        <v-col cols="12" md="3" sm="3" :class="'d-flex flex-column align-center'">
          <div :class="animate ? 'mb-4 animate__animated animate__flipInY' : 'mb-4'">
            <!-- <v-icon x-large>mdi-domain</v-icon> -->
            <img src="@/assets/icons/7.png" style="width: 50px" />
          </div>
          <div class="clean-list-title">Enterprise</div>
          <ul class="clean-list">
            <li>Campaign Setup</li>
            <li>2 channels (Facebook, Instagram)</li>
            <li>5 Advertisements</li>
            <li>30 Day Management</li>
            <li>Current Campaign Audit</li>
            <li>Target Audience Research</li>
            <li>Campaign Optimization</li>
            <li>Ads Analytical Report</li>
            <li>10 Day Delivery Time</li>
          </ul>
        </v-col>
        <v-col cols="12" class="d-flex align-center justify-center mt-8">
          <div>
            <v-btn
              color="darkgrey"
              @click="$emit('contact')"
              class="darkgrey--text"
              outlined
              tile
              large
              >Get a Quote</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "PageSix",
  data: () => ({
    animate: false,
    options: {
      threshold: [0, 0.5, 1.0],
    },
  }),
  methods: {
    onIntersect(entries) {
      this.animate = entries[0].isIntersecting;
    },
  },
};
</script>

<style></style>
