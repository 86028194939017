import firebase from 'firebase/app'
// import 'firebase/firestore'
// import 'firebase/auth'
// import 'firebase/analytics'
import 'firebase/functions'
// import 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyBcHqjKQrJEzEkOAvwSTFgoyZX1o5cAH7A',
  authDomain: 'kalli-kreative.firebaseapp.com',
  projectId: 'kalli-kreative',
  storageBucket: 'kalli-kreative.appspot.com',
  messagingSenderId: '512802175901',
  appId: '1:512802175901:web:66d9460e85301e14850d74',
  measurementId: 'G-NP5LR7Q77W',
}

const dev = false

firebase.initializeApp(firebaseConfig)

// const db = firebase.firestore()
// const auth = firebase.auth()
// const storage = firebase.storage()
// const analytics = firebase.analytics()
const functions = firebase.functions()
if (dev) functions.useEmulator('localhost', 5001)

// export { db, auth, storage, analytics, functions }
export { functions }
